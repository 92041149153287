<template>
  <div>
    <header-admin-pages
            title="Контакты для СМИ"
            :counter="getContacts.length"
            :button="headerButton"/>

    <table>
      <tr>
        <td>Заголовок</td>
        <td>Приоритет</td>
        <td class="drop-icon"></td>
      </tr>
      <tr v-for="contact in getContacts" :key="`contactBlock${contact.id}`">
        <td>{{ contact.title }}</td>
        <td>{{ contact.order }}</td>
        <td class="drop-icon">
          <!--<img src="/assets/icons/close.svg" @click="deleteNews(news.id)">-->
          <drop-list @clickItem="clickItem"
                     :id-item="contact.id"
                     :edit-link="`/admin/contacts/${contact.id}/edit`"/>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'

export default {
  name: 'Contacts',

  data () {
    return {
      headerButton: {
        title: 'Добавить блок обратной связи',
        link: '/admin/contacts/create'
      }
    }
  },

  beforeRouteLeave (to, from, next) {
    if (to.name === 'editContact' || to.name === 'createContacts') {
      this.$store.commit('setForMedia', true)
      next()
    } else {
      next()
    }
  },

  created () {
    this.$store.dispatch('getContacts')
  },

  methods: {
    deleteNews (id) {
      this.$store.dispatch('deleteContact', id)
    },

    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить контакт?')
        if (consent) {
          this.$store.dispatch('deleteContact', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      }
    }
  },

  mounted () {
    document.title = 'Для СМИ'
  },

  computed: {
    getContacts () {
      return this.$store.getters.getContacts
    }
  },

  components: {
    DropList,
    HeaderAdminPages
  }
}
</script>

<style scoped lang="scss">
  table{
    margin-top: 2rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;

        a {
          color: #246CB7;
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }

      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
</style>
